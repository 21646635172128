<template>
  <div class="join">
    <div class="join-wrapper">
      <div class="join-banner">
        <img src="../../assets/zlxcimgs/reat.png" alt="">
      </div>
      <div class="zlxc-container" style="overflow: visible">
        <div class="bread-crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>政策法规</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="join-concrete" v-if="dataSheetList.length > 0">
          <div class="list-content">
            <ul class="list-ul">
              <li class="list-li" v-for="item in dataSheetList" :key="item.id" @click="tapJumpDetail(item.id)">
                <div class="li-detail">
                  <p class="detail-line"></p>
                  <p class="detail-writing">{{item.title}}</p>
                </div>
                <div class="li-date">{{item.createDate}}</div>
              </li>
            </ul>
            <div class="newest-page">
              <el-pagination
                background
                layout="prev, pager, next"
                :total="pageObj.total"
                @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
          <div class="filter-content">
            <div class="filter-title">政策法规查询</div>
            <div class="form">
              <el-input
                class="form-input"
                prefix-icon="el-icon-search"
                placeholder="请输入您要查询的关键词"
                v-model="keyword"
                clearable>
              </el-input>
              <el-date-picker
                class="form-input"
                v-model="date"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择您要查询的日期">
              </el-date-picker>
            </div>
            <div class="filter-btn" @click="selectQuerySimpleLaws()">查询</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { querySimpleIndustryDynamics } from '@/api/herbalMedicine'
import { formatTime } from '@/utils/common.js'

export default {
  name: 'Join',
  data () {
    return {
      // 查询关键词
      keyword: '',
      // 查询日期
      date: '',
      // 分页信息
      pageObj: {
        pageNo: 1,
        pageSize: 10,
        total: 0
      },
      // 政策法规列表
      dataSheetList: [],
    }
  },
  created() {
    // 政策法规列表
    this.getQuerySimpleLaws()
  },
  mounted() {},
  computed: {},
  methods: {
    // 政策法规筛选
    selectQuerySimpleLaws () {
      this.pageObj.pageNo = 1
      this.getQuerySimpleLaws()
    },
    // 政策法规列表
    getQuerySimpleLaws () {
      querySimpleIndustryDynamics({
        type: 2,
        pageNo: this.pageObj.pageNo,
        pageSize: this.pageObj.pageSize,
        createTime: this.date,
        // publishStatus: 0,
        title: this.keyword
      }).then(res => {
        if (res.data.list.length > 0) {
          res.data.list.forEach(ele => {
            ele.createDate = formatTime(ele.createTime)
          })
          this.dataSheetList = res.data.list
          this.pageObj.total = res.data.total
          console.log('什么鬼')
          console.log(this.dataSheetList)
          console.log(res.data.list)
        } else {
          this.showMessage('未找到结果', "error");
        }
      })
    },
    // 当前currentPage 改变时会触发
    handleCurrentChange (curPage) {
      this.pageObj.pageNo = curPage
      this.getQuerySimpleLaws()
    },
    // 跳转详情页
    tapJumpDetail (id) {
      this.$router.push({
        name:'Details',
        query: {
          id,
          type: 'legislation'
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
